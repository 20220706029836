<div class="manga-detail-container">
  <!-- ローディングインジケータ -->
  <div *ngIf="isLoading" class="loading-container">
    <mat-spinner diameter="50"></mat-spinner>
    <p>漫画情報を読み込み中...</p>
  </div>

  <!-- エラーメッセージ -->
  <div *ngIf="error && !isLoading" class="error-container">
    <mat-icon color="warn">error</mat-icon>
    <p>{{ error }}</p>
    <button mat-raised-button color="primary" (click)="goBack()">トップページに戻る</button>
  </div>

  <!-- 漫画詳細表示 -->
  <article *ngIf="manga && !isLoading" class="manga-content" itemscope itemtype="https://schema.org/CreativeWork">
    <!-- パンくずリストを削除 -->
    
    <div class="back-button-container">
      <button mat-icon-button (click)="goBack()" aria-label="戻る">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>

    <!-- メイン情報 -->
    <mat-card class="manga-card">
      <div class="manga-header">
        <h1 class="manga-title" itemprop="name">{{ manga.title }}</h1>
        <p class="manga-author" (click)="navigateToAuthor(manga.author)" itemprop="author">作者: {{ manga.author }}</p>
      </div>

      <div class="manga-main-content">
        <div class="manga-thumbnail">
          <img [src]="manga.thumbnailUrl" [alt]="manga.title + ' - エロ漫画立ち読み市'" class="thumbnail-img" itemprop="image">
        </div>

        <div class="manga-info">
          <p class="manga-description" *ngIf="manga.description" itemprop="description">{{ manga.description }}</p>
          
          <div class="manga-tags">
            <h3>タグ</h3>
            <div class="tags-container">
              <mat-chip-set>
                <mat-chip *ngFor="let tag of manga.tags" (click)="navigateToTag(tag)" color="primary" itemprop="keywords">
                  {{ tag }}
                </mat-chip>
              </mat-chip-set>
            </div>
          </div>

          <div class="manga-price" *ngIf="manga.price">
            <h3>価格</h3>
            <p itemprop="offers" itemscope itemtype="https://schema.org/Offer">
              <span itemprop="price">{{ manga.price }}</span>
              <meta itemprop="priceCurrency" content="JPY">円
            </p>
          </div>

          <div class="manga-actions">
            <button mat-raised-button color="primary" *ngIf="manga.affiliateUrl" (click)="openPurchaseLink()">
              <mat-icon>shopping_cart</mat-icon> 購入する
            </button>
            <button mat-raised-button color="accent" *ngIf="manga.tachiyomiUrl" (click)="openPreviewLink()">
              <mat-icon>visibility</mat-icon> 立ち読みする
            </button>
          </div>
        </div>
      </div>

      <!-- サンプル画像がある場合 -->
      <div class="manga-samples" *ngIf="manga.sampleImageUrls && manga.sampleImageUrls.length > 0">
        <h3>サンプル画像</h3>
        <div class="sample-images">
          <div *ngFor="let img of manga.sampleImageUrls; let i = index" class="sample-image-container">
            <img [src]="img" [alt]="manga.title + ' サンプル画像 ' + (i+1) + ' - エロ漫画立ち読み市'" class="sample-img">
          </div>
        </div>
      </div>
    </mat-card>

    <!-- 関連作品 -->
    <!-- 同じ作者の作品 -->
    <section class="related-manga author-manga" *ngIf="sameAuthorManga && sameAuthorManga.length > 0">
      <h2>{{ manga.author }}の他の作品</h2>
      <div class="related-manga-grid">
        <div *ngFor="let related of sameAuthorManga" class="related-manga-item" (click)="loadMangaDetails(related.fanzaId)">
          <img [src]="related.thumbnailUrl" [alt]="related.title + ' - エロ漫画立ち読み市'" class="related-thumbnail">
          <p class="related-title">{{ related.title }}</p>
        </div>
      </div>
    </section>

    <!-- 同じジャンルの作品 -->
    <section class="related-manga genre-manga" *ngIf="sameGenreManga && sameGenreManga.length > 0">
      <h2>同じジャンルの作品</h2>
      <div class="related-manga-grid">
        <div *ngFor="let related of sameGenreManga" class="related-manga-item" (click)="loadMangaDetails(related.fanzaId)">
          <img [src]="related.thumbnailUrl" [alt]="related.title + ' - エロ漫画立ち読み市'" class="related-thumbnail">
          <p class="related-title">{{ related.title }}</p>
        </div>
      </div>
    </section>

    <!-- サイト名+作品名のテキスト（SEO用） -->
    <div class="seo-text">
      <h2>エロ漫画立ち読み市で「{{ manga.title }}」を立ち読み</h2>
      <p>「エロ漫画立ち読み市 {{ manga.title }}」で検索すれば、{{ manga.author }}先生の作品「{{ manga.title }}」の詳細情報や立ち読みサンプルをご覧いただけます。タグ：{{ manga.tags?.join('、') }}など、様々なジャンルの作品を多数取り揃えています。</p>
    </div>

    <!-- 下部ナビゲーション -->
    <div class="bottom-nav">
      <button mat-raised-button color="primary" (click)="goBack()">
        <mat-icon>home</mat-icon> トップページに戻る
      </button>
    </div>
  </article>
</div> 