<div class="manga-viewer" [class.mobile-view]="isMobileDevice" [class.desktop-view]="!isMobileDevice">
  <!-- カスタム年齢確認ダイアログ -->
  <div *ngIf="showCustomDialog" class="custom-dialog-overlay">
    <div class="custom-dialog">
      <h2 class="custom-dialog-title">年齢確認</h2>
      <div class="custom-dialog-content">
        <p>あなたは18歳以上ですか？</p>
      </div>
      <div class="custom-dialog-actions">
        <button class="btn-primary" (click)="onCustomConfirm()">はい</button>
        <button class="btn-secondary" (click)="onCustomCancel()">いいえ</button>
      </div>
    </div>
  </div>

  <!-- ローディング表示 -->
  <div *ngIf="isLoading" class="loading-container">
    <div class="loading-spinner"></div>
    <p>ムフフな漫画データを読み込み中...</p>
  </div>

  <!-- 漫画データがある場合の表示 -->
  <div *ngIf="mangaList.length > 0" class="manga-content">
    <div class="top-controls">
      <div class="manga-title-container" *ngIf="!isSearchResultMode && currentManga">
        {{ currentManga.title }}
      </div>
      <div class="top-buttons">
        <button class="search-toggle" (click)="toggleSearch()">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
        </button>
        <button class="hamburger-menu" (click)="toggleSidebar()">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
          </svg>
        </button>
      </div>
    </div>

    <!-- サイドバーメニュー -->
    <div *ngIf="isSidebarVisible" class="sidebar-menu">
      <button class="close-sidebar" (click)="closeSidebar()">×</button>
      <ul>
        <li><a href="https://x.com/ermncontent" target="_blank">運営者のXアカウント</a></li>
        <li (click)="showHelpModal()">ヘルプ</li>
        <!-- FANZAリンクをサイドメニューに追加 -->
        <li class="fanza-credit-sidebar">
          <a href="https://affiliate.dmm.com/api/">
            <img src="https://p.dmm.co.jp/p/affiliate/web_service/r18_135_17.gif" width="135" height="17" alt="WEB SERVICE BY FANZA">
          </a>
        </li>
        <!-- 他のメニュー項目をここに追加 -->
      </ul>
    </div>

    <!-- 検索コンテナとジャンル選択を統合 - オーバーレイ表示 -->
    <div class="search-overlay" *ngIf="isSearchVisible" (click)="hideSearch()">
      <div class="search-wrapper" [class.visible]="isSearchVisible" (click)="$event.stopPropagation()">
        <div class="search-container">
          <input
            type="text"
            class="search-input"
            [(ngModel)]="searchTerm"
            (ngModelChange)="onSearchChange($event)"
            placeholder="タイトル、作者、タグで検索..."
            (keydown.escape)="hideSearch()"
            autofocus
          />
        </div>
        
        <!-- ジャンル選択部分を検索欄の下に配置 -->
        <div class="genre-selection">
          <div class="genre-header">
            <h3>ジャンルで絞り込み</h3>
            <div class="genre-toggle" (click)="toggleGenreList()" *ngIf="isMobileDevice">
              <span>{{ isGenreListVisible ? '隠す' : '表示' }}</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" [style.transform]="isGenreListVisible ? 'rotate(180deg)' : 'rotate(0deg)'">
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
            </div>
          </div>
          
          <!-- 検索ボタンをジャンル絞り込みの直下に配置 -->
          <div class="search-button-container">
            <button class="search-button" (click)="executeSearch(); $event.stopPropagation();">
              検索する
            </button>
          </div>
          
          <div class="genre-list-container" [class.visible]="isGenreListVisible || !isMobileDevice">
            <div class="selected-genre" *ngIf="selectedGenres.length > 0">
              <div class="selected-genres-header">
                <span>選択中のジャンル: {{ selectedGenres.length }}件 (複数選択可能)</span>
                <button class="clear-all-genres" (click)="clearAllGenres()">すべて解除</button>
              </div>
              <div class="selected-genres-container">
                <div class="genre-badge" *ngFor="let genre of selectedGenres">
                  {{ genre }} <span class="clear-genre" (click)="selectGenre(genre)">×</span>
                </div>
              </div>
            </div>
            
            <div class="genres-section-title" *ngIf="genres.length > 0">
              利用可能なジャンル一覧 ({{ genres.length }}種類)
            </div>
            
            <div class="genre-list">
              <div 
                *ngFor="let genre of genres" 
                class="genre-tag" 
                [class.active]="genre.active"
                (click)="selectGenre(genre.genre)"
              >
                <span class="genre-name">{{ genre.genre }}</span>
                <span class="genre-count">({{ genre.count }})</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 検索結果表示モード（4枚グリッド表示） -->
    <div *ngIf="isSearchResultMode" class="manga-grid-container">
      <div class="search-mode-header">
        <h2>検索結果</h2>
        <button class="back-to-normal" (click)="exitSearchResultMode()">戻る</button>
      </div>
      
      <div class="manga-grid">
        <div *ngFor="let manga of mangaList" class="manga-card" (click)="selectMangaAndExit(manga)">
          <div class="manga-thumbnail">
            <img [src]="manga.thumbnailUrl" [alt]="manga.title" loading="lazy">
          </div>
          <div class="manga-info">
            <h3 class="manga-title">{{ manga.title }}</h3>
            <p class="manga-author">{{ manga.author }}</p>
          </div>
        </div>
      </div>
      
      <!-- ページネーションコントロール -->
      <div class="pagination-controls" *ngIf="mangaList.length > 0">
        <button (click)="fetchPreviousPage()" class="pagination-button" 
                [disabled]="isLoading || cursorHistory.length === 0">
          前のページ
        </button>
        <button (click)="fetchNextPage()" class="pagination-button" 
                [disabled]="isLoading || !hasMorePages">
          次のページ
        </button>
      </div>
      
      <!-- ローディング表示（追加データ読み込み中） -->
      <div *ngIf="isLoadingMore" class="loading-more">
        <div class="loading-spinner-small"></div>
        <p>もっと読み込み中...</p>
      </div>
    </div>

    <!-- 通常表示モード（単一漫画表示） -->
    <div *ngIf="!isSearchResultMode && currentManga" class="manga-single-container">
      
      <div class="manga-image-wrapper">
        <div class="manga-image-container" (click)="!isMobileDevice && goToTachiyomi()">
          <img [src]="getCurrentImage()" [alt]="currentManga.title" (load)="onImageLoad()" />
        </div>
      </div>
      
      <div class="controls-wrapper">
        <div class="controls">
          <button (click)="previousManga()" class="nav-button" 
                  [disabled]="isLoading || (currentIndex === 0 && cursorHistory.length === 0)">
            前へ
          </button>
          <button (click)="goToTachiyomi()" [disabled]="!currentManga.tachiyomiUrl" class="tachiyomi-button">
            立読ページへ
          </button>
          <button (click)="getRecommendations()" class="recommend-button" [disabled]="isLoading || !currentManga">
            似た作品
          </button>
          <button (click)="nextManga()" class="nav-button" [disabled]="isLoading || (!hasMorePages && currentIndex === mangaList.length - 1)">
            次へ
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- 検索結果なしのメッセージ -->
  <div *ngIf="!isLoading && mangaList.length === 0" class="no-results">
    <div class="error-message">
      <h3>漫画データが見つかりませんでした</h3>
      <p *ngIf="selectedGenres.length > 0 || searchTerm">検索条件に一致する漫画が見つかりませんでした。検索条件を変更してお試しください。</p>
      <p *ngIf="!selectedGenres.length && !searchTerm">漫画データの読み込みに失敗しました。通信環境をご確認ください。</p>
      <div class="error-actions">
        <button (click)="clearAllGenres(); searchTerm = ''; loadManga()" class="reload-button">
          すべての条件をクリアして再読み込み
        </button>
        <button (click)="toggleSearch()" class="search-again-button">
          検索条件を変更する
        </button>
      </div>
    </div>
  </div>

  <!-- 漫画詳細モーダル -->
  <div *ngIf="selectedManga" class="manga-detail-modal-overlay" (click)="closeSelectedManga()">
    <div class="manga-detail-modal" (click)="$event.stopPropagation()">
      <button class="close-modal" (click)="closeSelectedManga()">×</button>
      
      <div class="manga-detail-content">
        <div class="manga-detail-header">
          <h2>{{ selectedManga.title }}</h2>
          <p>作者: {{ selectedManga.author }}</p>
        </div>
        
        <div class="manga-detail-main">
          <div class="manga-detail-thumbnail">
            <img [src]="selectedManga.thumbnailUrl" [alt]="selectedManga.title">
          </div>
          
          <div class="manga-detail-info">
            <p *ngIf="selectedManga.description" class="manga-description">{{ selectedManga.description }}</p>
            
            <div class="manga-tags" *ngIf="selectedManga.tags && selectedManga.tags.length > 0">
              <h4>タグ</h4>
              <div class="tag-list">
                <span *ngFor="let tag of selectedManga.tags" class="tag-item" (click)="searchByTag(tag)">{{ tag }}</span>
              </div>
            </div>
            
            <div class="manga-price" *ngIf="selectedManga.price">
              <h4>価格</h4>
              <p>{{ selectedManga.price }}円</p>
            </div>
            
            <div class="manga-action-buttons">
              <button *ngIf="selectedManga.affiliateUrl" class="purchase-button" (click)="openPurchaseLink(selectedManga)">
                購入する
              </button>
              <button *ngIf="selectedManga.tachiyomiUrl" class="preview-button" (click)="openPreviewLink(selectedManga)">
                立ち読みする
              </button>
            </div>
          </div>
        </div>
        
        <!-- サンプル画像がある場合 -->
        <div class="manga-samples" *ngIf="selectedManga.sampleImageUrls && selectedManga.sampleImageUrls.length > 0">
          <h3>サンプル画像</h3>
          <div class="sample-images-container">
            <div *ngFor="let img of selectedManga.sampleImageUrls; let i = index" class="sample-image">
              <img [src]="img" [alt]="selectedManga.title + ' サンプル画像 ' + (i+1)" loading="lazy">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 使い方モーダル -->
  <div *ngIf="isHelpModalVisible" class="help-modal-overlay" (click)="closeHelpModal()">
    <div class="help-modal" (click)="$event.stopPropagation()">
      <button class="close-help-modal" (click)="closeHelpModal()">×</button>
      <h3>エロ漫画立ち読み市 の利用方法</h3>
      <ul>
        <li>検索すると、一覧表示で漫画を選べます</li>
        <li>通常表示では、左右のボタンで漫画を切り替えられます</li>
        <li>作品をタップすると詳細を確認できます</li>
        <li>「購入する」ボタンで公式サイトに移動できます</li>
        <li>「立ち読みする」ボタンでサンプルを読めます</li>
      </ul>
      <div class="help-modal-footer">
        <a href="https://www.dlsite.com/maniax/" target="_blank">作った奴のホームページ</a>
      </div>
    </div>
  </div>
</div>